import axios from "axios";


const apiUrl = "https://tokenex.nexa.org/api/validate";
export const validateToken = async (ticker: string,
    decimals: any,
    quantity: any,
    lockAmount: any,
    docUrl: string, destAddress: string, name: string) => {
    const formattedObject = {
        name,
        ticker,
        docUrl,
        decimals,
        quantity,
        destAddress,
        lockAmount
    }


    try {
        const response = await axios.post(
            apiUrl,
            formattedObject,

        );

        return response.data
    } catch (error: any) {
        if (error) {

            return error
        }
    }
}
