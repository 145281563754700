import React, { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./Home/Home";
import Name from "./Name/Name";
import Ticker from "./Ticker/Ticker";
import Document from "./Document/Document";
import Decimals from "./Decimals/Decimals";
import Quantity from "./Quantity/Quantity";
import Minting from "./Minting/Minting";
import Destination from "./Destination/Destination";
import Confirmation from "./Confirmation/Confirmation";
import Payment from "./Payment/Payment";
import Success from "./Success/Success";
import { AnimatePresence } from "framer-motion";
import Status from "./Status/Status";
import Failure from "./Failure/Failure";
import Search from "./Search/Search";
import SearchResult from "./SearchResult/SearchResult";

const AnimatedRoutes = () => {
  const location = useLocation();

  const [nameValue, setNameValue] = useState<string>("");
  const [tickerValue, setTickerValue] = useState<string>("");
  const [documentValue, setDocumentValue] = useState<string>("");
  const [decimalsValue, setDecimalsValue] = useState<number>(0);
  const [quantityValue, setQuantityValue] = useState<string>("");
  const [showTick, setShowTick] = useState<boolean>(true);
  const [destinationValue, setDestinationValue] = useState<string>("");
  const [payUrl, setPayUrl] = useState<string>("");
  const [payUrlString, setPayUrlString] = useState<string>("");
  const [payAmount, setPayAmount] = useState<number>(0);
  const [statusMessage, setStatusMessage] = useState<string>("");
  const [uId, setUid] = useState<any>("");
  const [mintId, setMintId] = useState<string>("");
  const [tokenTx, setTokenTx] = useState<string>("");
  const [processUID, setProcessUID] = useState<string>("");
  const [TokenId, setTokenId] = useState<string>("");
  const [metadataSig, setMetadataSig] = useState<string>("");
  const [searchUid, setSearchUid] = useState<string>("");
  const [statusSearch, setStatusSearch] = useState<string>("");
  const [mintIdSearch, setMintIdSearch] = useState<string>("");
  const [tokenTxSearch, setTokenTxSearch] = useState<string>("");
  const [processUIDSearch, setProcessUIDSearch] = useState<string>("");
  const [tokenIdSearch, setTokenIdSearch] = useState<string>("");
  const [metadataSigSearch, setMetadataSigSearch] = useState<string>("");
  const [destination, setDestinationError] = useState<string | null>(null);
  const [errorFailed, setErrorFailed] = useState<boolean>(false);
  const [errorCanceled, setErrorCanceled] = useState<boolean>(false);

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route
          path="/name"
          element={<Name nameValue={nameValue} setNameValue={setNameValue} />}
        />
        <Route
          path="/ticker"
          element={
            <Ticker tickerValue={tickerValue} setTickerValue={setTickerValue} />
          }
        />
        <Route
          path="/document"
          element={
            <Document
              documentValue={documentValue}
              setDocumentValue={setDocumentValue}
            />
          }
        />
        <Route
          path="/decimals"
          element={
            <Decimals
              decimalsValue={decimalsValue}
              setDecimalsValue={setDecimalsValue}
            />
          }
        />
        <Route
          path="/quantity"
          element={
            <Quantity
              quantityValue={quantityValue}
              setQuantityValue={setQuantityValue}
              decimalsValue={decimalsValue}
            />
          }
        />
        <Route
          path="/minting"
          element={<Minting showTick={showTick} setShowTick={setShowTick} />}
        />
        <Route
          path="/destination"
          element={
            <Destination
              destinationValue={destinationValue}
              setDestinationValue={setDestinationValue}
              tickerValue={tickerValue}
              decimalsValue={decimalsValue}
              quantityValue={quantityValue}
              isLocked={showTick}
              documentValue={documentValue}
              name={nameValue}
              destination={destination}
              setDestinationError={setDestinationError}
            />
          }
        />
        <Route
          path="/confirmation"
          element={
            <Confirmation
              destinationValue={destinationValue}
              setDestinationValue={setDestinationValue}
              tickerValue={tickerValue}
              decimalsValue={decimalsValue}
              quantityValue={quantityValue}
              isLocked={showTick}
              documentValue={documentValue}
              name={nameValue}
              setPayUrl={setPayUrl}
              setPayUrlString={setPayUrlString}
              setPayAmount={setPayAmount}
              setUid={setUid}
              setStatusMessage={setStatusMessage}
            />
          }
        />
        <Route
          path="/payment"
          element={
            <Payment
              uId={uId}
              payUrl={payUrl}
              payUrlString={payUrlString}
              payAmount={payAmount}
              setUid={setUid}
              setMintId={setMintId}
              setErrorFailed={setErrorFailed}
              setErrorCanceled={setErrorCanceled}
            />
          }
        />
        <Route
          path="/success"
          element={
            <Success
              mintId={mintId}
              tokenTx={tokenTx}
              processUID={processUID}
              TokenId={TokenId}
              metadataSig={metadataSig}
            />
          }
        />
        <Route
          path="/status"
          element={
            <Status
              uId={uId}
              setMintId={setMintId}
              setTokenTx={setTokenTx}
              setProcessUID={setProcessUID}
              setTokenId={setTokenId}
              setMetadataSig={setMetadataSig}
              setErrorFailed={setErrorFailed}
              setErrorCanceled={setErrorCanceled}
            />
          }
        />
        <Route
          path="/failure"
          element={
            <Failure
              destination={destination}
              errorFailed={errorFailed}
              errorCanceled={errorCanceled}
              processUIDSearch={processUIDSearch}
              processUID={processUID}
              uId={uId}
            />
          }
        />
        <Route
          path="/search"
          element={
            <Search
              searchUid={searchUid}
              setStatusSearch={setStatusSearch}
              setSearchUid={setSearchUid}
              setMintIdSearch={setMintIdSearch}
              setTokenTxSearch={setTokenTxSearch}
              setUid={setUid}
              setProcessUIDSearch={setProcessUIDSearch}
              setTokenIdSearch={setTokenIdSearch}
              setMetadataSigSearch={setMetadataSigSearch}
              setErrorFailed={setErrorFailed}
              setErrorCanceled={setErrorCanceled}
              setPayUrl={setPayUrl}
              setPayUrlString={setPayUrlString}
              setPayAmount={setPayAmount}
            />
          }
        />
        <Route
          path="/search-result"
          element={
            <SearchResult
              statusSearch={statusSearch}
              mintIdSearch={mintIdSearch}
              tokenTxSearch={tokenTxSearch}
              processUIDSearch={processUIDSearch}
              tokenIdSearch={tokenIdSearch}
              metadataSigSearch={metadataSigSearch}
            />
          }
        />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
