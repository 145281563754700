import React, { useCallback, useEffect, useState } from "react";
import ticker from "../../assets/ticker.gif";
import step from "../../assets/step-ticker.svg";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";


const Ticker = ({tickerValue, setTickerValue}: any) => {
  const [tickerError, setTickerError] = useState<string | null>(null);
  const [attemptedNext, setAttemptedNext] = useState(false);


  const handleTickerChange = useCallback((e: any) => {
    const newTicker = e.target.value;
    if (newTicker.length > 9) {
      setTickerError("Ticker should be less than 9 characters");
    } else {
      setTickerError(null);
      setTickerValue(newTicker);
    }
  }, []);


  const navigate = useNavigate();

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const regex = /^[A-Za-z0-9]*$/;
    if (!regex.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleNextClick = () => {
    setAttemptedNext(true);

    if (!tickerError && tickerValue && tickerValue.length < 9) {
      navigate("/document");
    } else {
      console.log("Invalid input or too long name");
    }
  };

  return (
    <div className="flex-row absolute top-1/2 left-1/2 h-screen max-h-[700px] w-[309.5px] md:w-screen md:max-h-[1600px] md:h-screen md:max-w-[450px] overflow-hidden" style={{transform: `translate(-50%, -50%)`}}>
      <div className="bg-gradient-to-tl from-customIndigo to-customFuchsia min-h-[43%] overflow-hidden relative">
        <div className="flex sm:mt-12 overflow-hidden  bg-white rounded-full w-36 h-36 mx-auto mt-7">
          <img
            src={`${ticker}?${Math.random()}`}
            className="flex w-28 mx-auto overflow-hidden my-auto"
            loading="lazy"
            alt="ticker"
          />
        </div>
        <div className="mx-auto my-auto sm:mx-auto sm:my-auto sm:items-center mt-4">
          <p className="mx-auto my-auto uppercase text-white sm:mt-2 font-sansArrow font-semibold leading-none">
            token ticker
          </p>
        </div>

        <div className="flex mx-auto bottom-0 left-0 right-0 py-3 absolute">
          <img className="w-72 mx-auto" src={step} alt="step" />
        </div>
      </div>

      <div className="flex bg-[#353539] h-[57%] ">
        <div className="mx-auto sm:w-72">
          <div className="w-[250px] mx-auto mt-5 ">
            <AnimatePresence>
              <motion.div
                initial={{ x: 150, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -150, opacity: 0 }}
                transition={{ type: "tween", duration: 0.8, ease: "easeInOut" }}
              >
                <div className="rounded">
                  <label className="relative block">
                    <input
                      className=" placeholder:text-gray-400 opacity-85 block bg-[#222229] w-full border-[1px] border-white rounded py-2 pl-4 pr-2 font-rajdhani font-medium text-white text-opacity-80 shadow-sm focus:outline-none focus:border-white  focus:ring-1 sm:text-sm"
                      placeholder="Your ticker..."
                      type="text"
                      name="search"
                      value={tickerValue}
                      onChange={handleTickerChange}
                      onKeyPress={handleKeyPress}
                    />
                  </label>
                  {(tickerError || (attemptedNext && !tickerValue)) && (
                <p className="absolute text-red-500 text-xs left-1/2 transform -translate-x-1/2 w-56">
                  {tickerError || "Ticker cannot be empty"}
                </p>
              )}
                </div>

                <ul className="flex-row sm:pl-8 list-disc mx-auto sm:float-left mt-5 w-64">
                  <li className="sm:text-clamp text-white font-sansArrow font-thin opacity-90 sm:text-left sm:pl-1 text-sm sm:flex-col sm:leading-5">
                    An exchange ticker is a short code that represents a
                    cryptocurrency or token.{" "}
                  </li>
                  <li className="sm:text-clamp text-white font-sansArrow font-light opacity-90 sm:text-left sm:pl-1 text-sm sm:leading-5">
                    {" "}
                    It consists of a combination of letters.
                  </li>
                  <li className="sm:text-clamp text-white font-sansArrow font-light opacity-90 sm:text-left sm:pl-1 text-sm sm:leading-5">
                    The ticker is typically used to track the token on an
                    exchange.
                  </li>
                  <li className="sm:text-clamp text-white font-sansArrow font-light opacity-90 sm:text-left sm:pl-1 text-sm sm:leading-5">
                    {" "}
                    Some examples of well-known tickers include BTC for Bitcoin,
                    ETH for Ethereum, and USDT for Tether.{" "}
                  </li>
                </ul>
              </motion.div>
            </AnimatePresence>

            <div className="mt-6">
              <button
                onClick={handleNextClick}
                className="shadow-up shadow-down bg-gradient-to-b from-custom-pink sm:mt-5 to-custom-purple text-[#353539] font-bold py-3 px-24 rounded-full"
              >
                Next
              </button>
            </div>
            <div>
              <button
                onClick={() => navigate("/name")}
                className="text-white font-semibold text-sm opacity-95 sm:mt-2"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ticker;
