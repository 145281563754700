import React, { useCallback, useEffect, useState } from "react";
import decimals from "../../assets/decimals.gif";
import step from "../../assets/step-decimals.svg";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const Decimals = React.memo(({ decimalsValue, setDecimalsValue }: any) => {
  const [decimalsError, setDecimalsError] = useState<string | null>(null);
  const [attemptedNext, setAttemptedNext] = useState(false);


  const handleNextClick = () => {
    if (!decimalsError && decimalsValue >= 0) {
      setDecimalsError(null);
      setAttemptedNext(true);
      setDecimalsValue(decimalsValue);
      const initialQuantity = parseFloat(`1${"0".repeat(decimalsValue + 1)}`);

      navigate("/quantity", { state: { initialQuantity, decimalsValue } });
    } else if (decimalsValue < 0) {
      setDecimalsError("Decimals cannot be negative");
    } else if (decimalsValue > 18) {
      setDecimalsError("Decimals must be between 0 and 18");
    } else {
      setDecimalsError("Invalid input");
    }
  };


  const handleIncrement = useCallback(() => {
    setDecimalsValue((prevCount: number) => Math.min(prevCount + 1, 18));
  }, []);

  const handleDecrement = useCallback(() => {
    setDecimalsValue((prevCount: number) => Math.max(prevCount - 1, 0));
  }, []);

  const navigate = useNavigate();

  return (
    <div
      className="flex-row absolute top-1/2 left-1/2 h-screen max-h-[700px] w-[309.5px] md:w-screen md:max-h-[1600px] md:h-screen md:max-w-[450px] overflow-hidden"
      style={{ transform: `translate(-50%, -50%)` }}
    >
      <div className="bg-gradient-to-tl from-customIndigo to-customFuchsia min-h-[43%] overflow-y-hidden relative">
        <div className="flex sm:mt-12 overflow-hidden  bg-white rounded-full w-36 h-36 mx-auto mt-7">
          <img
            src={`${decimals}?${Math.random()}`}
            alt="ticker"
            className="flex w-28 mx-auto overflow-hidden my-auto "
          />
        </div>
        <div className="mx-auto my-auto sm:mx-auto sm:my-auto sm:items-center mt-4">
          <p className="mx-auto my-auto uppercase text-white sm:mt-2 font-sansArrow font-semibold leading-none">
            decimals
          </p>
        </div>
        <div className="flex mx-auto bottom-0 left-0 right-0 py-3 absolute">
          <img className="w-72 mx-auto" src={step} alt="step" />
        </div>
      </div>
      <div className="flex bg-[#353539] h-[57%] ">
        <div className="mx-auto sm:w-72">
          <AnimatePresence>
            <motion.div
              initial={{ x: 150, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -150, opacity: 0 }}
              transition={{ type: "tween", duration: 0.8, ease: "easeInOut" }}
            >
              <div className="flex items-center justify-center w-[139px] h-10 mt-5 mx-auto">
                <div className="rounded-l">
                  <input
                    type="number"
                    className="flex px-[8px] py-[6px] sm:pl-2  w-[53px] border bg-[#222229] text-center rounded-l text-white border-white font-rajdhani outline-none"
                    value={decimalsValue}
                    id="numberInput"
                  />
                </div>

                <div className="">
                  <button
                    className="px-3 h-[37.5px]  font-rajdhani bg-[#222229] border text-white border-white hover:bg-gray-800"
                    onClick={handleIncrement}
                  >
                    <p className="text-3xl">+</p>
                  </button>
                </div>
                <div className="">
                  <button
                    className="px-3 font-rajdhani h-[37px] sm:h-[37.5px] bg-[#222229] rounded-r  border text-white border-white hover:bg-gray-800 text-center"
                    onClick={handleDecrement}
                  >
                    <p className="text-4xl ">-</p>
                  </button>
                </div>
                {(decimalsError || (attemptedNext && !decimalsValue)) && (
                  <p className="absolute text-red-500 text-xs left-1/2 transform -translate-x-1/2 w-56">
                    {decimalsError || ""}
                  </p>
                )}
              </div>

              <ul className="flex-row sm:pl-4 mx-auto sm:float-left mt-[10px] w-64 mr-[58px]">
                <li className="sm:text-clamp text-white font-sansArrow font-normal opacity-90 sm:text-left sm:pl-3 text-sm sm:flex-col sm:leading-5">
                  How divisible would you like your token to be. This determines
                  how many sub-units your token will have.{" "}
                </li>
                <li className="sm:text-clamp text-white font-sansArrow font-normal opacity-90 sm:text-left sm:pl-3 text-sm sm:flex-col sm:leading-5 sm:mt-2">
                  For example, US dollars have cents and there 100 cents per
                  dollar. So the US dollar has 2 decimal places.{" "}
                </li>
                <li className="sm:text-clamp text-white font-sansArrow font-normal opacity-90 sm:text-left sm:pl-3 text-sm sm:flex-col sm:leading-5 sm:mt-2">
                  {" "}
                  Bitcoin has 8 decimal places as there are 100,000,000
                  satoshi’s per Bitcoin.{" "}
                </li>
              </ul>
            </motion.div>
          </AnimatePresence>
          <div className="w-[250px] h-[203px] mx-auto sm:mt-14 mt-2">
            <div className="mt-3 sm:mt-[0.1px]">
              <button
                onClick={handleNextClick}
                className="shadow-up shadow-down bg-gradient-to-b from-custom-pink sm:mt-[12px] to-custom-purple text-[#353539] font-bold py-3 px-24 rounded-full"
              >
                Next
              </button>
            </div>
            <div>
              <button
                onClick={() => navigate("/document")}
                className="text-white font-semibold text-sm opacity-95 sm:mt-2"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Decimals;
