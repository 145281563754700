import React from "react";
import success from "../../assets/success.svg";
import { AnimatePresence, motion } from "framer-motion";

const Success = ({
  mintId,
  tokenTx,
  processUID,
  TokenId,
  metadataSig,
}: any) => {
  const reload = () => {
    window.location.reload();
  };

  return (
    <div
      className="flex-row absolute top-1/2 left-1/2 h-screen max-h-[700px] w-[309.5px] md:w-screen md:max-h-[1600px] md:h-screen md:max-w-[450px] overflow-hidden"
      style={{ transform: `translate(-50%, -50%)` }}
    >
      <div className="bg-gradient-to-tl from-customBlue to-customGreen min-h-[43%] overflow-y-hidden relative">
        <div className="flex sm:mt-12 overflow-hidden  bg-white rounded-full w-36 h-36 mx-auto mt-7">
          <img
            src={success}
            alt="ticker"
            className="flex w-36 mx-auto overflow-hidden my-auto "
          />
        </div>
        <div className="mx-auto my-auto sm:mx-auto sm:my-auto sm:items-center mt-4">
          <p className="mx-auto my-auto uppercase text-white sm:mt-2 font-sansArrow font-semibold leading-none">
            complete!
          </p>
        </div>
      </div>
      <div className="flex bg-[#353539] h-[57%]">
        <div className="mx-auto sm:w-72">
          <AnimatePresence>
            <motion.div
              initial={{ x: 150, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -150, opacity: 0 }}
              transition={{ type: "tween", duration: 0.8, ease: "easeInOut" }}
            >
              <div className="flex-row justify-between sm:pl-4 h-[276px] mx-auto sm:float-left mt-[15px] w-[290px] mr-[58px] overflow-y-auto overflow-x-hidden" >
                <div className=" sm:text-clamp text-white font-sansArrow font-normal opacity-95 sm:text-left sm:pl-1 text-sm sm:flex-col sm:leading-5 sm:mb-3">
                  Congratulations, you are officially the proud owner of a new
                  group of tokens on Nexa!{" "}
                </div>
                <div className=" sm:text-clamp text-white font-sansArrow font-normal opacity-95 sm:text-left sm:pl-1 text-sm sm:flex-col sm:leading-5 sm:mb-1">
                  Find the details below. Make sure to save them.{" "}
                </div>
                <div className="inline-block	sm:ml-1 whitespace-nowrap sm:justify-between sm:w-[250px] text-left">
                  <div
                    className="inline sm:flex-wrap "
                  >
                    <div className="inline sm:text-clamp text-white font-sansArrow font-bold text-sm  sm:leading-5 sm:mt-5 sm:w-12 sm:align-top">
                      Mint TX:
                    </div>
                    <div className="inline-block  whitespace-normal w-[280px] underline  sm:text-[10px] mt-1 mb-1 text-white font-sansArrow font-normal  pr-2 opacity-95  sm:align-top sm:ml-[-67px]" style={{ wordBreak: "break-all"}}>
                      <br></br>
                      {mintId ? mintId : "None"}
                    </div>
                    <div className="inline sm:text-clamp text-white font-sansArrow font-bold text-sm  sm:leading-5 sm:mt-5 sm:w-12 sm:align-top">
                      {" "}
                      <br></br>
                      Token TX:{" "}
                    </div>
                    <div className="inline-block whitespace-normal w-[280px] underline sm:text-[10px] mt-1 mb-1 text-white font-sansArrow font-normal  pr-2 opacity-95 sm:w-[270px] sm:align-top sm:ml-[-75px]" style={{ wordBreak: "break-all"}}>
                      <br></br>
                      {tokenTx ? tokenTx : "None"}
                    </div>
                    <div className="inline sm:text-clamp text-white font-sansArrow font-bold text-sm  sm:leading-5 sm:mt-5 sm:w-12 sm:align-top">
                      {" "}
                      <br></br>
                      Process UID:{" "}
                    </div>
                    <div className="inline-block whitespace-normal w-[280px] underline sm:text-[10px] mt-1 mb-1 text-white font-sansArrow font-normal  pr-2 opacity-95 sm:w-[270px] sm:align-top sm:ml-[-89px]" style={{ wordBreak: "break-all"}}>
                      <br></br>
                      {processUID ? processUID : "None"}
                    </div>
                    <div className="inline sm:text-clamp text-white font-sansArrow font-bold text-sm  sm:leading-5 sm:mt-5 sm:w-12 sm:align-top">
                      {" "}
                      <br></br>
                      Token ID:{" "}
                    </div>
                    <div className="inline-block whitespace-normal w-[280px] underline sm:text-[10px] mt-1 mb-1 text-white font-sansArrow font-normal  pr-2 opacity-95 sm:w-[270px] sm:align-top sm:ml-[-75px]" style={{ wordBreak: "break-all"}}>
                      <br></br>
                      {TokenId ? TokenId : "None"}
                    </div>
                    <div className="inline sm:text-clamp text-white font-sansArrow font-bold text-sm  sm:leading-5 sm:mt-5 sm:w-12 sm:align-top">
                      {" "}
                      <br></br>
                      Metadata Sig:{" "}
                    </div>
                    <div
                      style={{ wordBreak: "break-all" }}
                      className="inline-block whitespace-normal w-[280px] underline sm:text-[9.5px] mt-5 mb-1 text-white font-sansArrow font-normal  pr-2 opacity-95 sm:w-[270px] sm:align-top sm:ml-[-97px]"
                    >
                      {metadataSig ? (
                        metadataSig
                      ) : (
                        <p className="inline-block underline sm:text-[10px] text-white font-sansArrow font-normal  pr-2 opacity-95 sm:align-top sm:ml-[25px]">
                          None
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
          <div className="mt-[54px]">
            <button
              onClick={reload}
              className="shadow-up shadow-down bg-gradient-to-b from-custom-pink sm:mt-[1.8px] to-custom-purple text-[#353539] py-[11.8px] px-[72px] rounded-full font-bold"
            >
              Start Again
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
