import React from "react";
import failure from "../../assets/failure.svg";
import { AnimatePresence, motion } from "framer-motion";

const Failure = ({
  destination,
  errorFailed,
  errorCanceled,
  processUIDSearch,
  processUID,
  uId,
}: any) => {
  const reload = () => {
    window.location.reload();
  };

  return (
    <div
      className="flex-row absolute top-1/2 left-1/2 h-screen max-h-[700px] w-[309.5px] md:w-screen md:max-h-[1600px] md:h-screen md:max-w-[450px] overflow-hidden"
      style={{ transform: `translate(-50%, -50%)` }}
    >
      <div className="bg-gradient-to-tl from-customRed to-customWhite min-h-[43%] overflow-y-hidden relative">
        <div className="flex sm:mt-12 overflow-hidden  bg-white rounded-full w-36 h-36 mx-auto mt-7">
          <img
            src={failure}
            alt="ticker"
            className="flex w-36 mx-auto overflow-hidden my-auto "
          />
        </div>
        <div className="mx-auto my-auto sm:mx-auto sm:my-auto sm:items-center mt-4">
          <p className="mx-auto my-auto uppercase text-white sm:mt-2 font-sansArrow font-semibold leading-none">
            problem
          </p>
        </div>
      </div>
      <div className="flex bg-[#353539] h-[57%]">
        <div className="mx-auto sm:w-72">
          <AnimatePresence>
            <motion.div
              initial={{ x: 150, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -150, opacity: 0 }}
              transition={{ type: "tween", duration: 0.8, ease: "easeInOut" }}
            >
              <div
                className="flex-row justify-between sm:pl-4 h-64 mx-auto sm:float-left mt-[20px] w-[282px] mr-[58px]"
                style={{ overflowX: "hidden" }}
              >
                <div
                  className=" sm:text-clamp text-white font-sansArrow font-normal opacity-95 sm:text-left sm:pl-1 text-sm sm:flex-col sm:leading-5 sm:mb-4 overflow-y-auto overflow-x-hidden whitespace-normal sm:w-[235px]"
                  style={{ wordBreak: "break-all" }}
                >
                  {destination ? (
                    <p>There has been a problem with validation: </p>
                  ) : (
                    <p>There has been a problem with tokenization. </p>
                  )}
                  {errorCanceled && errorFailed ? (
                    <p>Canceled</p>
                  ) : errorCanceled ? (
                    <p>Operation was canceled</p>
                  ) : errorFailed ? (
                    <p>Minting failed</p>
                  ) : (
                    ""
                  )}
                  <br />
                  {destination ? destination : ""}
                </div>
                <div className=" sm:text-clamp text-white font-sansArrow font-normal opacity-95 sm:text-left sm:pl-1 text-sm sm:flex-col sm:leading-5 sm:mb-4">
                  Please contact us on our telegram group from the link below
                  and provide one our team with the Mint ID below and we will
                  help resolve the issue.{" "}
                </div>
                <div className="inline-block	sm:mr-[4px] whitespace-nowrap sm:justify-between sm:w-[250px] text-left">
                  <div className="inline sm:flex-wrap  	">
                    <div className="inline sm:text-clamp text-white font-sansArrow font-bold text-sm  sm:leading-5 sm:mt-5 sm:w-12 sm:align-top">
                      Status:
                    </div>
                    <div
                      className="inline sm:text-clamp  text-white font-sansArrow font-normal text-sm sm:w-12 sm:pr-3 sm:ml-[10px] opacity-95 sm:align-top"
                      style={{ wordBreak: "break-all" }}
                    >
                      {errorCanceled && errorFailed
                        ? "Canceled"
                        : errorCanceled
                        ? "Canceled"
                        : errorFailed
                        ? "Failed"
                        : destination
                        ? "Failed"
                        : "None"}
                    </div>
                    <div className="inline sm:text-clamp text-white font-sansArrow font-bold  text-sm  sm:leading-5 sm:mt-2 whitespace-normal sm:align-top">
                      {" "}
                      <br></br>
                      Telegram:{" "}
                    </div>
                    <div className="inline-block underline sm:text-clamp text-white font-sansArrow font-normal  text-sm pr-2 sm:ml-[10px] opacity-95 sm:align-top">
                      <a
                        href="https://t.me/nexacoin"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://t.me/nexacoin
                      </a>
                    </div>

                    <div
                      className=" inline sm:text-clamp whitespace-normal w-[270px] text-white font-sansArrow font-normal opacity-95 sm:text-left sm:pl-1 text-[9px] sm:flex-col sm:leading-5 sm:mb-4 overflow-y-auto overflow-x-hidden"
                      style={{ wordBreak: "break-all" }}
                    >
                      <div className="inline sm:text-clamp text-white font-sansArrow font-bold  text-sm  sm:leading-5  whitespace-normal sm:align-top">
                        {" "}
                        <br></br>
                        Process UID:{" "}
                      </div>
                      <div
                        style={{ wordBreak: "break-all" }}
                        className="inline-block whitespace-normal w-[230px] underline sm:text-[12px] text-white font-sansArrow font-normal  pr-2 opacity-95 sm:align-top sm:ml-[-1px]"
                      >
                        {processUIDSearch ? (
                          processUIDSearch
                        ) : processUID ? (
                          processUID
                        ) : uId ? (
                          uId
                        ) : (
                          <p className="inline-block underline sm:text-[14px] text-white font-sansArrow font-normal pr-2 opacity-95 sm:align-top sm:ml-[24px]">
                            None
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
          <div className="mt-[54px]">
            <button
              onClick={reload}
              className="shadow-up shadow-down bg-gradient-to-b from-custom-pink to-custom-purple text-[#353539] py-[11.8px] px-[72px] sm:mt-[10px] rounded-full font-bold"
            >
              Start Again
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Failure;
