import React, { useCallback, useState } from "react";
import name from "../../assets/name.svg";
import step from "../../assets/step-name.svg";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const Name = ({ nameValue, setNameValue }: any) => {
  const [nameError, setNameError] = useState<string | null>(null);
  const [attemptedNext, setAttemptedNext] = useState(false);


  const handleNameChange = useCallback((e: any) => {
    const newName = e.target.value;
    const regex = /^[A-Za-z0-9 ]*$/;
    if (newName.length > 20 && regex.test(newName)) {
      setNameError("Name should be less than 20 characters");
    } else {
      setNameError(null);
      setNameValue(newName);
    }
  }, []);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const regex = /^[A-Za-z0-9 ]*$/;
    if (!regex.test(e.key)) {
      e.preventDefault();
    }
  };

  const navigate = useNavigate();

  const handleNextClick = () => {
    setAttemptedNext(true);

    if (!nameError && nameValue && nameValue.length <= 20) {
      navigate("/ticker");
    } else {
      console.log("Invalid input or too long name");
    }
  };

  return (
    <div
      className="flex-row absolute top-1/2 left-1/2 h-screen max-h-[700px] w-[309.5px] md:w-screen md:max-h-[1600px] md:h-screen md:max-w-[450px] overflow-hidden"
      style={{ transform: `translate(-50%, -50%)` }}
    >
      <div className="bg-gradient-to-tl from-customIndigo to-customFuchsia min-h-[43%] overflow-y-hidden relative">
        <div className="flex sm:mt-12 overflow-hidden  bg-white rounded-full w-36 h-36 mx-auto mt-7">
          <motion.img
            src={`${name}?${Math.random()}`}
            alt="name"
            className="flex w-16 mx-auto overflow-hidden my-auto"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          />
        </div>
        <div className="mx-auto my-auto sm:mx-auto sm:my-auto sm:items-center mt-4">
          <p className="mx-auto my-auto uppercase text-white sm:mt-2 font-sansArrow font-semibold leading-none">
            token name
          </p>
        </div>
        <AnimatePresence>
          <motion.div
            initial={{ x: 0, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 0, opacity: 0 }}
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.1 },
            }}
          >
            <div className="flex mx-auto bottom-0 left-0 right-0 py-3 absolute">
              <img className="w-72 mx-auto" src={step} alt="step" />
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
      <div className="flex bg-[#353539] h-[57%] ">
        <div className="mx-auto sm:w-72">
          <div className="w-[250px] mx-auto mt-5 ">
            <AnimatePresence>
              <motion.div
                initial={{ x: 150, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -150, opacity: 0 }}
                transition={{ type: "tween", duration: 0.8, ease: "easeInOut" }}
              >
                <div className="rounded">
                  <label className="relative block">
                    <input
                      className={`placeholder:text-gray-400 opacity-85 block bg-[#222229] w-full border-[1px] border-white rounded py-2 pl-4 pr-2 font-rajdhani font-medium text-white text-opacity-80 shadow-sm focus:outline-none focus:border-white  focus:ring-1 sm:text-sm ${
                        nameError ? "border-red-500" : ""
                      }`}
                      placeholder="Name it something great..."
                      type="text"
                      name="search"
                      value={nameValue}
                      onChange={handleNameChange}
                      onKeyPress={handleKeyPress}
                    />
                  </label>
                  {(nameError || (attemptedNext && !nameValue)) && (
                    <p className="absolute text-red-500 text-xs left-1/2 transform -translate-x-1/2 w-56">
                      {nameError || "Name cannot be empty"}
                    </p>
                  )}
                </div>

                <ul className="flex-row sm:pl-8 list-disc mx-auto sm:float-left mt-5 w-64">
                  <li className="sm:text-clamp text-white font-sansArrow font-thin opacity-90 sm:text-left sm:pl-1 text-sm sm:flex-col leading-5">
                    Choose a name that is unique and memorable.{" "}
                  </li>
                  <li className="sm:text-clamp text-white font-sansArrow font-light opacity-90 sm:text-left sm:pl-1 text-sm leading-5">
                    Consider the purpose of the token and choose a name that
                    reflects this.
                  </li>
                  <li className="sm:text-clamp text-white font-sansArrow font-light opacity-90 sm:text-left sm:pl-1 text-sm leading-5">
                    Keep the name short and easy to pronounce.
                  </li>
                  <li className="sm:text-clamp text-white font-sansArrow font-light opacity-90 sm:text-left sm:pl-1 text-sm leading-5">
                    {" "}
                    Avoid using numbers or special characters.{" "}
                  </li>
                  <li className="sm:text-clamp text-white font-sansArrow  font-light opacity-90 sm:text-left sm:pl-1 text-sm leading-5">
                    {" "}
                    Consider the potential for the name to be easily misspelled
                    or misunderstood.{" "}
                  </li>
                </ul>
              </motion.div>
            </AnimatePresence>
            <div className="mt-6">
              <button
                onClick={handleNextClick}
                className="shadow-up shadow-down bg-gradient-to-b from-custom-pink sm:mt-5 to-custom-purple text-[#353539] font-bold py-3 px-24 rounded-full"
              >
                Next
              </button>
            </div>
            <div>
              <button
                onClick={() => navigate("/")}
                className="text-white font-semibold text-sm opacity-95 sm:mt-2"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Name;
