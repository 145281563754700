import axios from "axios";


export const statusToken = async (
    uId: any
) => {
    const apiUrl = `https://tokenex.nexa.org/api/status/${uId}`;

    try {
        const response = await axios.get(
            apiUrl,
        );

        return response.data;
    } catch (error) {

        console.error("Error:", error);
    }
}
