import axios from "axios";



const apiUrl = "https://tokenex.nexa.org/api/create";
export const createToken = async (ticker: any,
    decimals: any,
    quantity: any,
    lockAmount: any,
    docUrl: any, destAddress: any, name: string) => {
    const formattedObject = {
        name,
        ticker,
        docUrl,
        decimals,
        quantity,
        destAddress,
        lockAmount
    }

    try {
        const response = await axios.post(
            apiUrl,
            formattedObject,

        );

        return response.data;
    } catch (error) {

        console.error("Error:", error);
    }
}
