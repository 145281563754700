import React from "react";
import "./App.css";

import AnimatedRoutes from "./components/AnimatedRoutes";

function App() {
  return (
    <div className="App md:w-[450px] max-h-[700px] sm:mx-auto sm:my-auto min-h-screen w-screen h-screen sm:h-screen overflow-hidden max-w-full">
      <div className="mx-auto  my-auto sm:w-[100vw] md:h-screen  shadow-2xl overflow-hidden max-w-full">
      {/* mx-auto my-auto shadow-2xl max-w-[320px] w-[320px] max-h-[700px] overflow-hidden */}
        <div className="">
          <AnimatedRoutes />
        </div>
      </div>
    </div>
  );
}

export default App;
