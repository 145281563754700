import React from "react";
import home from "../../assets/home.svg";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="flex-row absolute top-1/2 left-1/2 h-screen max-h-[700px] md:w-screen md:max-h-[1600px] md:h-screen md:max-w-[450px] overflow-hidden" style={{transform: `translate(-50%, -50%)`}}>
      <div className="bg-gradient-to-tl from-customIndigo to-customFuchsia h-[43%] overflow-hidden ">
        <motion.div
          className="flex sm:float-right sm:mt-12 sm:mr-[-60px] overflow-hidden  bg-white rounded-full w-36 h-36 mx-auto mt-7 "
          initial={{ opacity: 0, left: 100 }}
          animate={{ opacity: 1, left: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.img
            src={`${home}?${Math.random()}`}
            alt=""
            className="flex w-24 mx-auto overflow-hidden my-auto "
            initial={{ left: -50 }}
            animate={{ left: 0 }}
            transition={{ duration: 0.5 }}
          />
        </motion.div>
        <div className="mx-auto my-auto sm:mx-auto sm:my-auto sm:items-center sm:mt-[100px] ">
          <div className="flex-row  mx-auto my-auto">
            <motion.p
              className="uppercase text-white font-bold leading-8 mx-auto my-auto sm:text-left	text-4xl font-sansArrow sm:ml-8 mt-4 "
              initial={{ x: 150 }}
              animate={{ x: 0 }}
              transition={{ duration: 0.5 }}
            >
              join the tokonomy
            </motion.p>
          </div>
          <div className="flex-row mx-auto my-auto ">
            <motion.p
              className="uppercase  text-white font-semibold	mx-auto my-auto	font-sansArrow sm:pt-3 sm:text-left sm:w-[190px] sm:ml-[35px] "
              initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1, delay: 0.8 }}
            >
              Use this tool to create & mint NEXA tokens.
            </motion.p>
          </div>
        </div>
      </div>

      <div className="flex bg-[#353539] h-[57%] ">
        <div className="mx-auto sm:w-72">
          <AnimatePresence>
            <motion.div
              initial={{ x: 150, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -150, opacity: 0 }}
              transition={{ type: "tween", duration: 0.8, ease: "easeInOut" }}
            >
              <p className="sm:text-clamp text-white font-bold font-sansArrow mt-4 sm:text-left text-center leading-5 text-sm sm:ml-4 ">
                This tool will allow you to create a new set of tokens in just a
                few seconds on the Nexa blockchain.
              </p>
              <p className="sm:text-clamp text-white font-sansArrow mt-4 font-light 	opacity-90 sm:text-left text-sm text-center sm:ml-4">
                You can use these tokens for all sorts of things including:
              </p>
              <ul className="sm:text-clamp flex-row sm:pl-8 list-disc mx-auto sm:float-left mt-3 w-32">
                <li className="sm:text-clamp text-white font-sansArrow font-thin opacity-90 sm:text-left sm:pl-1 text-sm sm:flex-col">
                  tickets,
                </li>
                <li className="sm:text-clamp text-white font-sansArrow font-light opacity-90 sm:text-left sm:pl-1 text-sm">
                  votes,{" "}
                </li>
                <li className="sm:text-clamp text-white font-sansArrow font-light opacity-90 sm:text-left sm:pl-1 text-sm">
                  shares,
                </li>
                <li className="sm:text-clamp text-white font-sansArrow font-light opacity-90 sm:text-left sm:pl-1 text-sm">
                  {" "}
                  money,{" "}
                </li>
                <li className="sm:text-clamp text-white font-sansArrow  font-light opacity-90 sm:text-left sm:pl-1 text-sm">
                  {" "}
                  and more.{" "}
                </li>
              </ul>
            </motion.div>
          </AnimatePresence>
          <div className="mt-[54px]">
            <button
              onClick={() => navigate("/name")}
              className="shadow-up shadow-down bg-gradient-to-b from-custom-pink sm:mt-[33px] to-custom-purple text-[#353539] py-3 px-24 rounded-full font-bold"
            >
              Start
            </button>
            <div>
              <button
                onClick={() => navigate("/search")}
                className="text-white font-semibold text-sm opacity-95 sm:mt-2"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
;

export default Home;
