import React, { useEffect } from "react";
import status from "../../assets/status.gif";
import step from "../../assets/step-status.svg";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { statusToken } from "../../Api/Status/Status";


const Status = ({ uId, setMintId, setTokenTx, setProcessUID, setTokenId, setMetadataSig, setErrorFailed, setErrorCanceled }: any) => {
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const response = await statusToken(uId);
        if (response.status === "Done") {
          clearInterval(interval);
          setMintId(response.mintTxIdem);
          setTokenTx(response.createTxIdem);
          setProcessUID(response.uid);
          setTokenId(response.tokenId);
          setMetadataSig(response.jsonSignature);
          navigate("/success");
        } else if (response.status === "Failed") {
          clearInterval(interval);
          setMintId(response.mintTxIdem);
          setProcessUID(response.uid);
          setErrorFailed(true);
          navigate("/failure");
        } else if (response.status === "Canceled") {
          setMintId(response.mintTxIdem);
          setProcessUID(response.uid);
          clearInterval(interval);
          setErrorCanceled(true);
          navigate("/failure");
        }
      } catch (error) {
        console.error("Error fetching status:", error);
        navigate("/failure");
        setErrorFailed(true);
      }
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [uId, setMintId, setTokenTx, setProcessUID, setTokenId, navigate, setMetadataSig, setErrorFailed, setErrorCanceled]);

  return (
    <div className="flex-row absolute top-1/2 left-1/2 h-screen max-h-[700px] w-[309.5px] md:w-screen md:max-h-[1600px] md:h-screen md:max-w-[450px] overflow-hidden" style={{transform: `translate(-50%, -50%)`}}>
      <div className="bg-gradient-to-tl from-customBlue to-customGreen min-h-[43%] overflow-y-hidden relative">
        <div className="flex sm:mt-12 overflow-hidden  bg-white rounded-full w-36 h-36 mx-auto mt-7">
          <img
            src={status}
            alt="ticker"
            className="flex w-28 mx-auto overflow-hidden my-auto "
          />
        </div>
        <div className="mx-auto my-auto sm:mx-auto sm:my-auto sm:items-center mt-4">
          <p className="mx-auto my-auto uppercase text-white sm:mt-2 font-sansArrow font-semibold leading-none">
            payment received
          </p>
        </div>
        <div className="flex mx-auto bottom-0 left-0 right-0 py-3 absolute">
          <img className="w-72 mx-auto" src={step} alt="step" />
        </div>
      </div>
      <div className="flex bg-[#353539] h-[57%]">
        <div className="mx-auto sm:w-72">
          <AnimatePresence>
            <motion.div
              initial={{ x: 150, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -150, opacity: 0 }}
              transition={{ type: "tween", duration: 0.8, ease: "easeInOut" }}
            >
              <ul className="flex-row sm:pl-4 h-42 mx-auto sm:float-left mt-[20px] w-[270px] mr-[58px]">
                <li className="sm:text-clamp text-white font-sansArrow font-normal sm:text-left sm:pl-1 text-sm sm:flex-col sm:leading-5">
                  We have received your payment. Thanks!{" "}
                </li>
                <li className="sm:text-clamp text-white font-sansArrow font-normal sm:text-left sm:pl-1 text-sm sm:flex-col sm:leading-5 sm:mt-2">
                  {" "}
                  Your token group is now being generated, and your minted
                  tokens and token authority are now being sent to you...{" "}
                </li>
              </ul>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Status;
