import React, { useCallback, useEffect, useState } from "react";
import document from "../../assets/document.gif";
import step from "../../assets/step-document.svg";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const Document = ({documentValue, setDocumentValue}: any) => {
  const [documentError, setDocumentError] = useState<string | null>(null);

  const handleDocumentChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    const isValidStart = newValue.startsWith("http") || newValue.startsWith("https");

    if (isValidStart || newValue === "") {
      setDocumentError(null);

      if (isValidStart) {
        const restOfValue = newValue.slice(4);
        const regex = /^[A-Za-z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\/\-=\\|]*$/;

        if (restOfValue && !regex.test(restOfValue)) {
          setDocumentError("Only English letters and digits are allowed after 'http'");
        } else {
          setDocumentValue(newValue);
        }
      } else {
        setDocumentValue(newValue);
      }
    } else {
      setDocumentError("Input must start with 'http' or 'https'");
    }
  }, []);


  const navigate = useNavigate();


  return (
    <div className="flex-row absolute top-1/2 left-1/2 h-screen max-h-[700px] w-[309.5px] md:w-screen md:max-h-[1600px] md:h-screen md:max-w-[450px] overflow-hidden" style={{transform: `translate(-50%, -50%)`}}>
      <div className="bg-gradient-to-tl from-customIndigo to-customFuchsia min-h-[43%] overflow-y-hidden relative">
        <div className="flex sm:mt-12 overflow-hidden  bg-white rounded-full w-36 h-36 mx-auto mt-7">
            <img
              src={`${document}?${Math.random()}`}
              alt="ticker"
              className="flex w-[110px] mx-auto overflow-hidden my-auto "
            />
        </div>
        <div className="mx-auto my-auto sm:mx-auto sm:my-auto sm:items-center mt-4">
          <p className="mx-auto my-auto uppercase text-white sm:mt-2 font-sansArrow font-semibold leading-none">
            document
          </p>
        </div>
        <div className="flex mx-auto bottom-0 left-0 right-0 py-3 absolute">
          <img className="w-72 mx-auto" src={step} alt="step" />
        </div>
      </div>
      <div className="flex bg-[#353539] h-[57%] ">
        <div className="mx-auto sm:w-72">
          <AnimatePresence>
            <motion.div
              initial={{ x: 150, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -150, opacity: 0 }}
              transition={{ type: "tween", duration: 0.8, ease: "easeInOut" }}
            >
              <ul className="flex-row sm:pl-4 mx-auto sm:float-left mt-4 w-64 mr-[58px] sm:mb-5">
                <li className="sm:text-clamp text-white font-sansArrow font-thin opacity-90 sm:text-left sm:pl-1 text-sm sm:flex-col sm:leading-5">
                  Enter JSON metadata URL for your token.{" "}
                </li>
              </ul>
              <div className="w-[250px] h-[203px]  mx-auto ">
              <div className="rounded">
                <label className="relative block">
                  <textarea
                    className=" placeholder:text-gray-400 opacity-85 block bg-[#222229] w-full border-[1px] border-white rounded pb-[115px] pl-4 pr-2 pt-2 font-rajdhani font-medium text-white text-opacity-80 shadow-sm focus:outline-none focus:border-white align-top	  focus:ring-1 sm:text-sm"
                    placeholder="URL (optional)"
                    name="search"
                    value={documentValue}
                    onChange={handleDocumentChange}
                  ></textarea>
                </label>
                {documentError && (
                    <p className="absolute text-red-500 text-xs left-1/2 transform -translate-x-1/2 w-56">{documentError}</p>
                  )}
              </div>
              </div>
            </motion.div>
          </AnimatePresence>
          <div className="w-[250px] mx-auto sm:mt-[26.5px] ">
            <div className="mt-3 sm:mt-[0.1px]">
              <button
                onClick={() => navigate("/decimals")}
                className="shadow-up shadow-down bg-gradient-to-b from-custom-pink sm:mt-7 to-custom-purple text-[#353539] font-bold py-3 px-24 rounded-full"
              >
                Next
              </button>
            </div>
            <div>
              <button
                onClick={() => navigate("/ticker")}
                className="text-white font-semibold text-sm opacity-95 sm:mt-2"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Document;
