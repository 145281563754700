import React, { useCallback, useEffect, useState } from "react";
import quantity from "../../assets/quantity.gif";
import step from "../../assets/step-quantity.svg";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const Quantity = ({ quantityValue, setQuantityValue, decimalsValue }: any) => {
  const [quantityError, setQuantityError] = useState<string | null>(null);
  const [attemptedNext, setAttemptedNext] = useState<boolean>(false);

  const handleQuantityChange = useCallback(
    (e: any) => {
      const newValue = e.target.value;

      try {
        const parsedValue = BigInt(newValue);

        if (parsedValue > 0n) {
          const formattedValue = parsedValue.toString();
          setQuantityValue(formattedValue);
          setQuantityError(null);
        } else {
          setQuantityValue(newValue);
          setQuantityError(
            "Quantity should be a whole number and bigger than 0"
          );
        }
      } catch (error) {
        console.error("Error parsing input:", error);
        setQuantityValue(newValue);
        setQuantityError("Quantity should be a whole number and bigger than 0");
      }
    },
    [quantityValue]
  );

  const handleNextClick = () => {
    setAttemptedNext(true);

    if (!quantityError && quantityValue) {
      navigate("/minting");
    } else {
      console.log("Invalid input or too long name");
    }
  };


  const navigate = useNavigate();

  return (
    <div
      className="flex-row absolute top-1/2 left-1/2 h-screen max-h-[700px] w-[309.5px] md:w-screen md:max-h-[1600px] md:h-screen md:max-w-[450px] overflow-hidden"
      style={{ transform: `translate(-50%, -50%)` }}
    >
      <div className="bg-gradient-to-tl from-customIndigo to-customFuchsia min-h-[43%] overflow-y-hidden relative">
        <div className="flex sm:mt-12 overflow-hidden  bg-white rounded-full w-36 h-36 mx-auto mt-7">
          <img
            src={`${quantity}?${Math.random()}`}
            alt="ticker"
            className="flex w-28 mx-auto overflow-hidden my-auto "
          />
        </div>
        <div className="mx-auto my-auto sm:mx-auto sm:my-auto sm:items-center mt-4">
          <p className="mx-auto my-auto uppercase text-white sm:mt-2 font-sansArrow font-semibold leading-none">
            quantity
          </p>
        </div>
        <div className="flex mx-auto bottom-0 left-0 right-0 py-3 absolute">
          <img className="w-72 mx-auto" src={step} alt="step" />
        </div>
      </div>

      <div className="flex bg-[#353539] h-[57%]">
        <div className="mx-auto sm:w-72">
          <AnimatePresence>
            <motion.div
              initial={{ x: 150, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -150, opacity: 0 }}
              transition={{ type: "tween", duration: 0.8, ease: "easeInOut" }}
            >
              <div className="w-[250px] mx-auto mt-5 ">
                <div className="rounded">
                  <label className="relative block">
                    <input
                      className=" placeholder:text-gray-400 opacity-85 block bg-[#222229] w-full border-[1px] border-white rounded py-2 pl-4 pr-2 font-rajdhani font-medium text-white text-opacity-80 shadow-sm focus:outline-none focus:border-white  focus:ring-1 sm:text-sm"
                      type="number"
                      name="search"
                      value={quantityValue}
                      onChange={handleQuantityChange}
                      onKeyPress={(e) => {
                        if (e.key === "-" || e.key === "e") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </label>
                  {(quantityError || (attemptedNext && !quantityValue)) && (
                    <p className="absolute text-red-500 text-xs left-1/2 transform -translate-x-1/2 w-80">
                      {quantityError || "Quantity cannot be empty"}
                    </p>
                  )}
                </div>
              </div>
              <ul className="flex-row sm:pl-4 h-44 mx-auto sm:float-left mt-[20px] w-64 mr-[58px]">
                <li className="sm:text-clamp text-white font-sansArrow font-normal opacity-90 sm:text-left sm:pl-1 text-sm sm:flex-col sm:leading-5">
                  How many tokens should there be of your token initially?{" "}
                </li>
                <li className="sm:text-clamp text-white font-sansArrow font-normal opacity-90 sm:text-left sm:pl-1 text-sm sm:flex-col sm:leading-5 sm:mt-2">
                  {" "}
                  Make sure you have enough for your goals with the project.{" "}
                </li>
              </ul>
            </motion.div>
          </AnimatePresence>
          <div className="w-[250px] h-[203px] mx-auto sm:mt-14 mt-2">
            <div className="mt-3 sm:mt-[0.1px]">
              <button
                onClick={handleNextClick}
                className="shadow-up shadow-down bg-gradient-to-b from-custom-pink sm:mt-[4.5px] to-custom-purple text-[#353539] font-bold py-3 px-24 rounded-full"
              >
                Next
              </button>
            </div>
            <div>
              <button
                onClick={() => navigate("/decimals")}
                className="text-white font-semibold text-sm opacity-95 sm:mt-2"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quantity;