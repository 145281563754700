import React from "react";
import minting from "../../assets/minting.gif";
import step from "../../assets/step-minting.svg";
import { useNavigate } from "react-router-dom";
import tick from "../../assets/tick.svg";
import { motion, AnimatePresence } from "framer-motion";

const Minting = ({ showTick, setShowTick }: any) => {
  const navigate = useNavigate();


  return (
    <div
      className="flex-row absolute top-1/2 left-1/2 h-screen max-h-[700px] w-[309.5px] md:w-screen md:max-h-[1600px] md:h-screen md:max-w-[450px] overflow-hidden"
      style={{ transform: `translate(-50%, -50%)` }}
    >
      <div className="bg-gradient-to-tl from-customIndigo to-customFuchsia min-h-[43%] overflow-y-hidden relative">
        <div className="flex sm:mt-12 overflow-hidden  bg-white rounded-full w-36 h-36 mx-auto mt-7">
          <img
            src={`${minting}?${Math.random()}`}
            alt="ticker"
            className="flex w-28 mx-auto overflow-hidden my-auto "
          />
        </div>
        <div className="mx-auto my-auto sm:mx-auto sm:my-auto sm:items-center mt-4">
          <p className="mx-auto my-auto uppercase text-white sm:mt-2 font-sansArrow font-semibold leading-none">
            supply
          </p>
        </div>
        <div className="flex mx-auto bottom-0 left-0 right-0 py-3 absolute">
          <img className="w-72 mx-auto" src={step} alt="step" />
        </div>
      </div>
      <div className="flex bg-[#353539] h-[57%]">
        <div className="mx-auto sm:w-72">
          <AnimatePresence>
            <motion.div
              initial={{ x: 150, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -150, opacity: 0 }}
              transition={{ type: "tween", duration: 0.8, ease: "easeInOut" }}
            >
              <div className="flex flex-row mx-auto my-auto mt-5 w-52">
                <div className="flex mx-auto my-auto">
                  <p className="flex-row uppercase text-white font-bold text-sm font-sansArrow ">
                    Lock the amount forever?
                  </p>
                </div>
                <div className="flex w-9 h-9 border-white border-[1px] rounded-md cursor-pointer bg-[#222229]">
                  <button
                    className="w-5 h-5 mx-auto my-auto"
                    onClick={() => {
                      const newShowTickValue = !showTick;
                      setShowTick(newShowTickValue);
                      
                    }}
                  >
                    {showTick && (
                      <img
                        src={tick}
                        alt="tick"
                        className="w-5 h-5 mx-auto my-auto"
                      />
                    )}
                  </button>
                </div>
              </div>
              <ul className="flex-row sm:pl-5 h-40 mx-auto sm:float-left mt-[10px] w-[270px] mr-[58px]">
                <li className="sm:text-clamp text-white font-sansArrow font-normal opacity-90 sm:text-left sm:pl-1 text-sm sm:flex-col sm:leading-5">
                  Would you like the previously entered quantity of tokens to be
                  maximum amount there will ever be? This is useful for certain
                  types of tokens.{" "}
                </li>
                <li className="sm:text-clamp text-white font-sansArrow font-normal opacity-90 sm:text-left sm:pl-1 text-sm sm:flex-col sm:leading-5 sm:mt-3">
                  {" "}
                  If this option is disabled then you will be able to mint more
                  of these tokens in the future using your authority UTXO.{" "}
                </li>
              </ul>
            </motion.div>
          </AnimatePresence>
          <div className="w-[250px] h-[203px] mx-auto sm:mt-14 mt-2">
            <div className="mt-4 sm:mt-[0.1px]">
              <button
                onClick={() => navigate("/destination")}
                className="shadow-up shadow-down bg-gradient-to-b from-custom-pink sm:mt-[31.5px] to-custom-purple text-[#353539] font-bold py-3 px-24 rounded-full"
              >
                Next
              </button>
            </div>
            <div>
              <button
                onClick={() => navigate("/quantity")}
                className="text-white font-semibold text-sm opacity-95 sm:mt-2"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Minting;
