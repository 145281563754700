import axios from "axios";


export const searchStatus = async (
    searchUid: any
) => {
    const apiUrl = `https://tokenex.nexa.org/api/status/${searchUid}`;

    try {
        const response = await axios.get(
            apiUrl,
        );

        return response.data;
    } catch (error: any) {
        if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            return error;
          }
    }
}

