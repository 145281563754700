import React, { useCallback, useState } from "react";
import search from "../../assets/search.svg";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { searchStatus } from "../../Api/searchStatus/searchStatus";

const Search = ({
  searchUid,
  setSearchUid,
  setStatusSearch,
  setMintIdSearch,
  setTokenTxSearch,
  setProcessUIDSearch,
  setTokenIdSearch,
  setMetadataSigSearch,
  setErrorFailed,
  setErrorCanceled,
  setPayUrl,
  setPayUrlString,
  setPayAmount,
  setUid
}: any) => {
  const [searchUidError, setSearchUidError] = useState<string | null>(null);
  const [attemptedNext, setAttemptedNext] = useState(false);
  const [notFound, setNotFound] = useState<boolean>(false);

  const handleSearch = useCallback((e: any) => {
    const value = e.target.value;
    console.log("Input value:", value);
    setSearchUid(value);

    if (value.trim() === "") {
      setSearchUidError("This field is required.");
    } else {
      setSearchUidError(null);
    }
  }, []);

  const handleRequest = async () => {
    setAttemptedNext(true);
    try {
      const response = await searchStatus(searchUid);
      if (response) {
        if (response.status === "Done") {
          setStatusSearch(response.status);
          setMintIdSearch(response.mintTxIdem);
          setTokenTxSearch(response.createTxIdem);
          setProcessUIDSearch(response.uid);
          setTokenIdSearch(response.tokenId);
          setMetadataSigSearch(response.jsonSignature);
          navigate("/search-result");
        } else if (response.status === "Pending") {
          navigate("/payment");
          setUid(response.uid)
          setProcessUIDSearch(response.uid);
          setStatusSearch(response.status);
          setPayUrl(response.payUrl);
          setPayUrlString(response.payAddress);
          setPayAmount(response.payAmount)
        } else if (response.status === "Canceled") {
          setErrorCanceled(true);
          setProcessUIDSearch(response.uid);
          setMintIdSearch(response.mintTxIdem);
          navigate("/failure");
        } else if (response.status === "Failed") {
          setErrorFailed(true);
          setProcessUIDSearch(response.uid);
          setMintIdSearch(response.mintTxIdem);
          navigate("/failure");
        } else if (
          response.status === "Creating" ||
          response.status === "Minting"
        ) {
          navigate("/status");
        } else if (response?.response?.data?.error) {
          setNotFound(response?.response?.data?.error);
          setNotFound(true);
        }
      }
    } catch (error: any) {
      console.log("ERROR", error);
      if (error) {
        console.log("ERROR", error);
      }
    }
  };

  const navigate = useNavigate();

  return (
    <div
      className="flex-row absolute top-1/2 left-1/2 h-screen max-h-[700px] w-[309.5px] md:w-screen md:max-h-[1600px] md:h-screen md:max-w-[450px] overflow-hidden"
      style={{ transform: `translate(-50%, -50%)` }}
    >
      <div className="bg-gradient-to-tl from-customIndigo to-customFuchsia min-h-[43%] overflow-y-hidden relative">
        <div className="flex sm:mt-12 overflow-hidden  bg-white rounded-full w-36 h-36 mx-auto mt-7">
          <img
            src={search}
            alt="ticker"
            className="flex w-28 mx-auto overflow-hidden my-auto "
          />
        </div>
        <div className="mx-auto my-auto sm:mx-auto sm:my-auto sm:items-center mt-4">
          <p className="mx-auto my-auto uppercase text-white sm:mt-2 font-sansArrow font-semibold leading-none">
            search
          </p>
        </div>
      </div>
      <div className="flex bg-[#353539] h-[57%]">
        <div className="mx-auto sm:w-72">
          <AnimatePresence>
            <motion.div
              initial={{ x: 150, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -150, opacity: 0 }}
              transition={{ type: "tween", duration: 0.8, ease: "easeInOut" }}
            >
              <div className="w-[250px] mx-auto mt-5 ">
                <div className="rounded">
                  <label className="relative block">
                    <input
                      className={`placeholder:text-gray-400 opacity-85 block bg-[#222229] w-full border-[1px] border-white rounded py-2 pl-4 pr-2 font-rajdhani font-medium text-white text-opacity-80 shadow-sm focus:outline-none focus:border-white focus:ring-1 sm:text-sm ${
                        attemptedNext && searchUid.trim() === ""
                          ? "border-red-500"
                          : ""
                      }`}
                      placeholder="Enter a token creation UID..."
                      type="text"
                      name="search"
                      value={searchUid}
                      onChange={handleSearch}
                      required
                    />
                    {(searchUidError || (attemptedNext && !searchUid)) && (
                      <p className="absolute text-red-500 text-xs left-1/2 transform -translate-x-1/2 w-56">
                        {searchUidError || "Search cannot be empty"}
                      </p>
                    )}
                    {notFound ? (
                      <p className="absolute text-red-500 text-xs left-1/2 transform -translate-x-1/2 w-56">
                        UID not found
                      </p>
                    ) : (
                      ""
                    )}
                  </label>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
          <div className="w-[250px] mx-auto sm:mt-48 mt-2">
            <div className="mt-3 sm:mt-[0.1px]">
              <button
                onClick={handleRequest}
                disabled={searchUidError !== null || searchUid.trim() === ""}
                className="shadow-up shadow-down bg-gradient-to-b from-custom-pink sm:mt-2 to-custom-purple text-[#353539] py-[11.8px] px-[89.5px] rounded-full font-bold"
              >
                Search
              </button>
            </div>
            <div>
              <button
                onClick={() => navigate("/")}
                className="text-white font-semibold text-sm opacity-95 sm:mt-2"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
