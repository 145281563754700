import React from "react";
import success from "../../assets/success.svg";
import { AnimatePresence, motion } from "framer-motion";

const SearchResult = ({
  statusSearch,
  mintIdSearch,
  tokenTxSearch,
  processUIDSearch,
  tokenIdSearch,
  metadataSigSearch,
}: any) => {

  const reload = () => {
    window.location.reload();
  }

  return (
    <div className="flex-row absolute top-1/2 left-1/2 h-screen max-h-[700px] w-[309.5px] md:w-screen md:max-h-[1600px] md:h-screen md:max-w-[450px] overflow-hidden" style={{transform: `translate(-50%, -50%)`}}>
      <div className="bg-gradient-to-tl from-customBlue to-customGreen min-h-[43%] overflow-y-hidden relative">
        <div className="flex sm:mt-12 overflow-hidden  bg-white rounded-full w-36 h-36 mx-auto mt-7">
          <img
            src={success}
            alt="ticker"
            className="flex w-36 mx-auto overflow-hidden my-auto "
          />
        </div>
        <div className="mx-auto my-auto sm:mx-auto sm:my-auto sm:items-center mt-4">
          <p className="mx-auto my-auto uppercase text-white sm:mt-2 font-sansArrow font-semibold leading-none">
            token
          </p>
        </div>
      </div>
      <div className="flex bg-[#353539] h-[57%]">
        <div className="mx-auto sm:w-72">
          <AnimatePresence>
            <motion.div
              initial={{ x: 150, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -150, opacity: 0 }}
              transition={{ type: "tween", duration: 0.8, ease: "easeInOut" }}
            >
              <div className="flex-row justify-between sm:pl-4 h-[280px] mx-auto sm:float-left mt-[2px] w-[290px] mr-[58px] overflow-y-auto overflow-x-hidden">
                <div className=" sm:text-clamp text-white font-sansArrow font-normal opacity-95 sm:text-left sm:pl-1 text-sm sm:flex-col sm:leading-5 sm:mb-2">
                  Find the token creation details below. Make sure to save them.{" "}
                </div>
                <div className="inline-block sm:mt-[-8px]	sm:ml-1 whitespace-nowrap sm:justify-between sm:w-[250px] text-left">
                  <div className="inline sm:flex-wrap  	">
                    <div className="inline sm:text-clamp text-white font-sansArrow font-bold text-sm  sm:leading-5  sm:w-12 sm:align-top">
                      Status:
                    </div>
                    <div className="inline sm:text-clamp text-white font-sansArrow font-normal text-sm sm:w-12 sm:pr-3 sm:ml-[10px] opacity-95 sm:align-top whitespace-normal w-[280px]" style={{wordBreak: "break-all" }}>
                      {statusSearch ? statusSearch : "None"}
                    </div>
                    <div className="inline sm:text-clamp text-white font-sansArrow font-bold  text-sm  sm:leading-5 sm:mt-2 sm:align-top ">
                      {" "}
                      <br></br>
                      Mint TX:{" "}
                    </div>
                    <div style={{ wordBreak: "break-all" }} className="inline-block underline sm:text-[9.5px] mt-5 mb-1 text-white font-sansArrow font-normal  pr-2 opacity-95 sm:w-[270px] sm:align-top sm:ml-[-67px] whitespace-normal w-[280px]" >
                      {mintIdSearch ? mintIdSearch : <p className="inline-block underline sm:text-[14px] text-white font-sansArrow font-normal  pr-2 opacity-95 sm:align-top sm:ml-[25px]">None</p>}
                    </div>
                    <div className="inline sm:text-clamp text-white font-sansArrow font-bold  text-sm sm:mb-[-5px]  sm:leading-5  whitespace-normal sm:align-top">
                      {" "}
                      <br></br>
                      Token TX:{" "}
                    </div>
                    <div className="inline-block underline sm:text-[9.5px]  mb-1 text-white font-sansArrow font-normal  pr-2 opacity-95 sm:w-[270px] sm:align-top sm:ml-[-20px] whitespace-normal w-[280px]" style={{ wordBreak: "break-all" }}>
                      {tokenTxSearch ? tokenTxSearch : <p className="inline-block underline sm:text-[14px] text-white font-sansArrow font-normal  pr-2 opacity-95 sm:align-top sm:ml-[25px]">None</p>}
                    </div>
                    <div className="inline sm:text-clamp text-white font-sansArrow font-bold  text-sm  sm:leading-5  whitespace-normal sm:align-top">
                      {" "}
                      <br></br>
                      Process UID:{" "}
                    </div>
                    <div className="inline-block underline sm:text-[9.5px]  mb-1 text-white font-sansArrow font-normal  pr-2 opacity-95 sm:w-[270px] sm:align-top sm:ml-[-20px] whitespace-normal w-[280px]" style={{ wordBreak: "break-all" }}>
                      {processUIDSearch ? processUIDSearch : <p className="inline-block underline sm:text-[14px] text-white font-sansArrow font-normal  pr-2 opacity-95 sm:align-top sm:ml-[20px]">None</p>}
                    </div>
                    <div className="inline sm:text-clamp text-white font-sansArrow font-bold  text-sm  sm:leading-5  whitespace-normal sm:align-top">
                      {" "}
                      <br></br>
                      Token ID:{" "}
                    </div>
                    <div className="inline-block underline sm:text-[9.5px]  mb-1 text-white font-sansArrow font-normal  pr-2 opacity-95 sm:w-[270px] sm:align-top sm:ml-[-20px] whitespace-normal w-[280px]" style={{ wordBreak: "break-all" }}>
                      {tokenIdSearch ? tokenIdSearch : <p className="inline-block underline sm:text-[14px] text-white font-sansArrow font-normal  pr-2 opacity-95 sm:align-top sm:ml-[25px]">None</p>}
                    </div>
                    <div className="inline sm:text-clamp text-white font-sansArrow font-bold  text-sm  sm:leading-5 sm:mt-2 whitespace-normal sm:align-top">
                      {" "}
                      <br></br>
                      Metadata Sig:{" "}
                    </div>
                    <div style={{ wordBreak: "break-all" }} className="inline-block underline sm:text-[9.5px] mb-1 text-white font-sansArrow font-normal  pr-2 opacity-95 sm:w-[270px] sm:align-top sm:ml-[-20px] whitespace-normal w-[280px]" >
                      {metadataSigSearch ? metadataSigSearch :
                      <p className="inline-block underline sm:text-[14px] text-white font-sansArrow font-normal  pr-2 opacity-95 sm:align-top ">None</p>
                      }
                      
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
          <div className="mt-[54px]">
            <button
              onClick={reload}
              className="shadow-up shadow-down bg-gradient-to-b from-custom-pink sm:mt-[17px] to-custom-purple text-[#353539] py-[11.8px] px-[72px] rounded-full font-bold"
            >
              Start Again
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResult;
