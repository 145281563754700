import React, { useMemo } from "react";
import confirmation from "../../assets/confirmation.svg";
import step from "../../assets/step-confirmation.svg";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { createToken } from "../../Api/Create/Create";

const Confirmation = ({
  destinationValue,
  tickerValue,
  decimalsValue,
  quantityValue,
  isLocked,
  documentValue,
  name,
  setPayUrl,
  setPayUrlString,
  setPayAmount,
  setUid,
  setStatus,
}: any) => {
  const navigate = useNavigate();

  const handleRequest = async () => {
    try {
      const response = await createToken(
        tickerValue,
        decimalsValue,
        quantityValue,
        isLocked,
        documentValue,
        destinationValue,
        name
      );
      const payUrlFromResponse = response.payUrl;
      const payUrlStringFromResponse = response.payAddress;
      const PayAmountFromResponse = response.payAmount;
      const setUidFromResponse = response.uid;

      setPayUrl(payUrlFromResponse);
      setPayUrlString(payUrlStringFromResponse);
      setPayAmount(PayAmountFromResponse);
      setUid(setUidFromResponse);
      if (response.status === "Failed" || response.status === "Canceled") {
        navigate("/failed");
        setUid(setUidFromResponse);
      }
      navigate("/payment");
    }
    catch (e) {
      console.log("Error:", e);
    }
  };

  const truncatedDestination = useMemo(() => {
    const maxLength = 19;
    if (destinationValue.length > maxLength) {
      const start = destinationValue.substring(0, maxLength / 2);
      const end = destinationValue.substring(
        destinationValue.length - maxLength / 2
      );
      return `${start}...${end}`;
    }
    return destinationValue;
  }, [destinationValue]);

  return (
    <div className="flex-row absolute top-1/2 left-1/2 h-screen max-h-[700px] w-[309.5px] md:w-screen md:max-h-[1600px] md:h-screen md:max-w-[450px] overflow-hidden" style={{transform: `translate(-50%, -50%)`}}>
      <div className="bg-gradient-to-tl from-customIndigo to-customFuchsia min-h-[43%] overflow-y-hidden relative">
        <div className="flex sm:mt-12 overflow-hidden  bg-white rounded-full w-36 h-36 mx-auto mt-7">
          <img
            src={confirmation}
            alt="ticker"
            className="flex w-36 mx-auto overflow-hidden my-auto "
          />
        </div>
        <div className="mx-auto my-auto sm:mx-auto sm:my-auto sm:items-center mt-4">
          <p className="mx-auto my-auto uppercase text-white sm:mt-2 font-sansArrow font-semibold leading-none">
            confirmation
          </p>
        </div>
        <div className="flex mx-auto bottom-0 left-0 right-0 py-3 absolute">
          <img className="w-72 mx-auto" src={step} alt="step" />
        </div>
      </div>
      <div className="flex bg-[#353539] h-[57%]">
        <div className="mx-auto sm:w-72">
          <AnimatePresence>
            <motion.div
              initial={{ x: 150, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -150, opacity: 0 }}
              transition={{ type: "tween", duration: 0.8, ease: "easeInOut" }}
            >
              <ul className="flex-row sm:pl-4 h-7 mx-auto sm:float-left mt-[20px] w-[270px] mr-[58px]">
                <li className="sm:text-clamp text-white font-sansArrow font-normal opacity-90 sm:text-left sm:pl-1 text-sm sm:flex-col sm:leading-5">
                  Confirm the below token details:
                </li>
              </ul>
              <div className="flex-row justify-between sm:pl-4 h-44 mx-auto sm:float-left  w-[270px] mr-[58px]">
                <div className="inline-block	sm:ml-1 whitespace-nowrap sm:justify-between sm:w-[250px] text-left">
                  <div className="inline sm:flex-wrap  	">
                    <div className="inline sm:text-clamp text-white font-sansArrow font-bold text-sm  sm:leading-5 sm:mt-5 sm:w-12 ">
                      Token Name:
                    </div>
                    <div className="inline  sm:text-clamp text-white font-sansArrow font-normal text-sm sm:w-12 sm:pr-3 sm:ml-[10px] opacity-95">
                      {name ? name : "None"}
                    </div>
                    <div className="inline sm:text-clamp text-white font-sansArrow font-bold  text-sm  sm:leading-5 sm:mt-2 whitespace-normal">
                      {" "}
                      <br></br>
                      Ticker:{" "}
                    </div>
                    <div className="inline-block  sm:text-clamp text-white font-sansArrow font-normal  text-sm pr-2 sm:ml-[10px] opacity-95">
                      {tickerValue ? tickerValue : "None"}
                    </div>
                    <div className="inline sm:text-clamp text-white font-sansArrow font-bold  text-sm  sm:leading-5 sm:mt-2 whitespace-normal">
                      {" "}
                      <br></br>
                      Decimals:{" "}
                    </div>
                    <div className="inline-block  sm:text-clamp text-white font-sansArrow font-normal  text-sm pr-2 sm:ml-[10px] opacity-95">
                      {decimalsValue ? decimalsValue : 0}
                    </div>
                    <div className="inline sm:text-clamp text-white font-sansArrow font-bold  text-sm  sm:leading-5 sm:mt-2 whitespace-normal">
                      {" "}
                      <br></br>
                      Quantity:{" "}
                    </div>
                    <div className="inline-block  sm:text-clamp text-white font-sansArrow font-normal  text-sm pr-2 sm:ml-[10px] opacity-95">
                      {quantityValue ? quantityValue : "None"}
                    </div>
                    <div className="inline sm:text-clamp text-white font-sansArrow font-bold  text-sm  sm:leading-5 sm:mt-2 whitespace-normal">
                      {" "}
                      <br></br>
                      Supply:{" "}
                    </div>
                    <div className="inline-block  sm:text-clamp text-white font-sansArrow font-normal  text-sm pr-2 sm:ml-[10px] opacity-95">
                      {isLocked ? "Locked" : "Unlocked"}
                    </div>
                    <div className="inline sm:text-clamp text-white font-sansArrow font-bold  text-sm  sm:leading-5 sm:mt-2 whitespace-normal">
                      {" "}
                      <br></br>
                      Destination:{" "}
                    </div>
                    <div className="inline-block  sm:text-clamp text-white font-sansArrow font-normal  text-sm pr-2 sm:ml-[10px] opacity-95">
                      {truncatedDestination ? truncatedDestination : "None"}
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
          <div className="w-[250px]  mx-auto sm:mt-14 mt-2">
            <div className="mt-3 sm:mt-[0.1px]">
              <button
                onClick={() => handleRequest()}
                className="shadow-up shadow-down bg-gradient-to-b from-custom-pink sm:mt-[33.5px] to-custom-purple text-[#353539] font-bold py-3 px-24 rounded-full"
              >
                Next
              </button>
            </div>
            <div>
              <button
                onClick={() => navigate("/destination")}
                className="text-white font-semibold text-sm opacity-95 sm:mt-2"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
