import React, { useEffect, useRef, useState } from "react";
import payment from "../../assets/payment.gif";
import step from "../../assets/step-payment.svg";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { statusToken } from "../../Api/Status/Status";
import { QRCode } from "react-qrcode-logo";
import logo from "../../assets/nexa-logo.svg"

const Payment = ({
  uId,
  payUrl,
  payUrlString,
  payAmount,
  setUid,
  setErrorFailed,
  setErrorCanceled,
}: any) => {
  const navigate = useNavigate();

  const initialElapsedTime = 0;
  const [elapsedTime, setElapsedTime] = useState<number>(initialElapsedTime);

  const initialTime = 600;
  const [timeLeft, setTimeLeft] = useState<number>(initialTime);
  const intervalRef = useRef<any | null>(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
        setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
      } else {
        clearInterval(intervalRef.current);
      }
    }, 1000);
    if (timeLeft <= 0) {
      setUid(uId);
      navigate("/failure");
    } else if (elapsedTime >= 600) {
      setUid(uId);
      clearInterval(intervalRef.current);
      navigate("/failure");
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [timeLeft, elapsedTime]);

  useEffect(() => {
    const pollInterval = 3000;
    let intervalId: any;

    const fetchStatus = async () => {
      try {
        const response = await statusToken(uId);

        if (response.status === "Creating" || response.status === "Minting" || response.status === "Done") {
          clearInterval(intervalId);
          navigate("/status");
        } else if (response.status === "Failed") {
          setUid(uId);
          setErrorFailed(true);
          clearInterval(intervalId);
        } else if (response.status === "Canceled") {
          setUid(uId);
          setErrorCanceled(true);
          clearInterval(intervalId);
        }
        console.log("responseBefore", response);
      } catch (error) {
        setUid(uId);
        console.error("Error fetching status:", error);
        setErrorFailed(true);
        navigate("/failure");
      }
    };

    intervalId = setInterval(fetchStatus, pollInterval);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  const reload = () => {
    window.location.reload();
  };

  return (
    <div
      className="flex-row absolute top-1/2 left-1/2 h-screen max-h-[720px] w-[309.5px] md:w-screen md:max-h-[1600px] md:h-screen md:max-w-[450px] overflow-hidden"
      style={{ transform: `translate(-50%, -50%)` }}
    >
      <div className="bg-gradient-to-tl from-customIndigo to-customFuchsia min-h-[43%] overflow-y-hidden relative">
        <div className="flex sm:mt-12 overflow-hidden  bg-white rounded-full w-36 h-36 mx-auto mt-7">
          <img
            src={`${payment}?${Math.random()}`}
            alt="ticker"
            className="flex w-28 mx-auto overflow-hidden my-auto "
          />
        </div>
        <div className="mx-auto my-auto sm:mx-auto sm:my-auto sm:items-center mt-4">
          <p className="mx-auto my-auto uppercase text-white sm:mt-2 font-sansArrow font-semibold leading-none">
            payment
          </p>
        </div>
        <div className="flex mx-auto bottom-0 left-0 right-0 py-3 absolute">
          <img className="w-72 mx-auto" src={step} alt="step" />
        </div>
      </div>
      <div className="flex bg-[#353539] h-[57%]">
        <div className="mx-auto sm:w-72">
          <AnimatePresence>
            <motion.div
              initial={{ x: 150, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -150, opacity: 0 }}
              transition={{ type: "tween", duration: 0.8, ease: "easeInOut" }}
            >
              <div className="w-[282px] mt-6 mx-auto h-[240px] overflow-y-auto overflow-x-hidden">
                <div className="rounded">
                  <label className="relative block">
                    <input
                      className=" placeholder:text-white block bg-[#222229] w-full border-[1px] border-white rounded py-2 pl-4 pr-4 font-rajdhani font-medium text-white shadow-sm focus:outline-none focus:border-white  focus:ring-1 sm:text-xs outline-none"
                      type="text"
                      placeholder=""
                      name="search"
                      value={payUrlString ? payUrlString : "None"}
                    />
                  </label>
                  <div className="inline sm:text-clamp text-white font-sansArrow font-bold text-sm  sm:leading-5 sm:mt-5 sm:w-12 sm:mr-[200px]">
                    Process UID:
                  </div>
                  <div
                    className="sm:text-clamp text-white font-sansArrow font-normal opacity-90 text-sm text-left whitespace-normal w-[260px]"
                    style={{ wordBreak: "break-all" }}
                  >
                    {uId ? uId : "None"}
                     </div>
                </div>
                <div className="flex flex-row mx-auto sm:w-[280px] mt-2">
                  <div>
                    <QRCode
                      size={140}
                      quietZone={5}
                      value={payUrl}
                      logoImage={logo}
                      logoWidth={20}
                      logoPadding={1}
                    />
                  </div>
                  <div className="flex flex-col w-[100px] sm:ml-[35px] pt-3">
                    <p className="text-white font-sansArrow font-bold">
                      {payAmount ? payAmount.toLocaleString() : "None"} NEXA
                    </p>
                    <p className="sm:text-clamp text-white font-sansArrow font-normal opacity-90 text-sm text-left ml-[6px]">
                      Please pay the correct amount shown above.
                    </p>
                  </div>
                </div>
              </div>

              <ul className="flex-row h-4 mx-auto my-auto  mt-[10px] ">
                <li className="sm:text-clamp mt-[-5px] text-white font-sansArrow font-bold text-sm sm:leading-5">
                  Expires: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </li>
              </ul>
            </motion.div>
          </AnimatePresence>
          <div className="w-[250px] h-[203px] mx-auto mt-[10px]">
            <div className="">
              <button
                className="shadow-up shadow-down bg-gradient-to-b from-custom-pink to-custom-purple text-[#353539] font-bold py-3 px-[43.5px] rounded-full whitespace-nowrap"
              >
                <a
                  href="https://t.me/nexacoin"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Having a problem?
                </a>
              </button>
            </div>
            <div className="mt-[5px]">
              <button
                onClick={reload}
                className="shadow-up shadow-down bg-gradient-to-b from-custom-pink to-custom-purple text-[#353539] py-[11.8px] px-[72px] sm:mt-[10px] rounded-full font-bold"
              >
                Start Again
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
